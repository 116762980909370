import React, { useContext, useState } from "react";
import { workoutPartsInKR } from "../../../../../api/common/commonEnumType";
import { ImageView } from "design-system/components/ImageView/ImageView";
import Button from "design-system/components/Button/Button";
import arrowUpIcon from "design-system/components/atom/IconResource/ic_arrow_up.svg";
import { Textarea } from "design-system/components/Textarea/Textarea";
import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";
import { MixedWorkoutDetail } from "../../../../../api/trainer/schedules/schedulesTypes";
import CreateContext from "../../createContext";
import { ImageSelector } from "design-system/components/ImageSelector/ImageSelector";
import { EditableSetTable } from "../EditableSetTable/EditableSetTable";
import {
  convertImageToPng,
  extractVideoThumbnailUrl,
} from "../../../../../hoock/useUrlToFile";
import { DialogPopup } from "design-system/components/DialogPopup/DialogPopup";
import customImage from "design-system/components/atom/IconResource/customImage.svg";
import { PrevFeedbackDialog } from "../../../component/PrevFeedbackDialog/PrevFeedbackDialog";
import messageImage from "design-system/components/atom/IconResource/message-square-01.svg";

interface WorkoutItemProps {
  item: MixedWorkoutDetail;
  order: number;
  editWorkoutItem: (value: MixedWorkoutDetail) => void;
  eraseWorkoutItem: () => void;
}

export function WorkoutItem({
  item,
  order,
  editWorkoutItem,
  eraseWorkoutItem,
}: WorkoutItemProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const { data, setData } = useContext(CreateContext);
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  return (
    <>
      <div
        css={[
          containerCSS,
          css`
            background: ${isOpen ? colors.lightGray : colors.gray25};
          `,
        ]}
      >
        <div css={mainContainerCSS}>
          <div css={upperContainerCSS}>
            <div css={badgeContainerCSS}>
              <div css={badgeCSS}>{order}번째</div>
              {item.sets.length > 0 && (
                <div css={badgeCSS}>{item.sets.length}세트</div>
              )}
              <div css={badgeCSS}>{workoutPartsInKR(item.part)}</div>
              {item.sets.length > 0 && (
                <div css={badgeCSS}>
                  {item.sets.reduce(
                    (accumulator, currentSet) =>
                      accumulator + currentSet.weight * currentSet.count,
                    0,
                  )}
                  KG
                </div>
              )}
            </div>
            <Button css={buttonCSS} onClick={eraseWorkoutItem}>
              삭제하기
            </Button>
          </div>
          <div css={bodyContainerCSS} onClick={() => setIsOpen(!isOpen)}>
            <ImageView
              src={item.thumbnail ?? customImage}
              width={116}
              height={116}
              variant={"square"}
              isClickable={false}
            />
            <div css={workoutNameTextCSS}> {item.workoutName}</div>
            <Button variant={"icon"}>
              {
                <>
                  {!isOpen ? (
                    <img
                      src={arrowUpIcon}
                      style={{ transform: "rotate(180deg)" }}
                      height={40}
                      width={40}
                    />
                  ) : (
                    <img src={arrowUpIcon} height={40} width={40} />
                  )}
                </>
              }
            </Button>
          </div>
        </div>
        <div
          css={prevFeedbackCSS}
          onClick={() => setIsFeedbackDialogOpen(true)}
        >
          <img
            src={messageImage}
            css={css`
              height: 15px;
              width: 15px;
            `}
          />
          이전 피드백
        </div>
        {isOpen && (
          <div css={foldedContainerCSS}>
            {
              <div css={feedbackContainerCSS}>
                <div css={feedbackTextCSS}>피드백</div>
                <Textarea
                  isAutoHeight={true}
                  value={item.feedback}
                  onChange={(event) => {
                    const newWorkoutItem: MixedWorkoutDetail = {
                      ...item,
                      feedback: event.target.value,
                    };
                    editWorkoutItem(newWorkoutItem);
                  }}
                  styles={css`
                    ${typography.mobile.heading1}
                  `}
                />
              </div>
            }
            {
              <div css={feedbackContainerCSS}>
                <div css={feedbackTextCSS}>사진 및 동영상</div>
                <div css={imageListContainerCSS}>
                  {item.videosDetail.map((video, videoIndex) => (
                    <ImageSelector
                      key={`video-${Date.now()}-${videoIndex}`}
                      src={video.url}
                      variant={"square"}
                      videoThumbNailProps={video.thumbnail}
                      isVideoProps={true}
                      onImageRemove={() => {
                        editWorkoutItem({
                          ...item,
                          videosDetail: item.videosDetail.filter(
                            (_, innerIndex) => innerIndex !== videoIndex,
                          ),
                        });
                        return;
                      }}
                    />
                  ))}
                  {item.imagesDetail.map((image, imageIndex) => (
                    <ImageSelector
                      key={`image-${Date.now()}-${imageIndex}`}
                      src={image.url}
                      variant={"square"}
                      onImageRemove={() => {
                        editWorkoutItem({
                          ...item,
                          imagesDetail: item.imagesDetail.filter(
                            (_, innerIndex) => innerIndex !== imageIndex,
                          ),
                        });
                        return;
                      }}
                    />
                  ))}
                  {[
                    ...Array(
                      6 - item.imagesDetail.length - item.videosDetail.length,
                    ),
                  ].map((_, i) => (
                    <ImageSelector
                      key={`placeholder-${Date.now()}-${i}`}
                      variant={"square"}
                      onChange={async (event) => {
                        const input = event.target as HTMLInputElement;
                        // 파일이 삭제된 경우 처리
                        if (!input.files || input.files.length === 0) {
                          // 이 부분에서 필요에 따라 videos와 images 배열 모두에서 해당 인덱스 요소 삭제
                          // 예시에서는 videos와 images가 동일한 인덱스에서 삭제되지 않도록 조정해야 함
                          // 이를 위해 `index`나 `videoIndex`, `imageIndex` 등의 로직을 추가할 수 있습니다.
                          return;
                        }

                        // 파일이 있는 경우 (업로드된 경우)
                        if (input.files[0]) {
                          const file = input.files[0];
                          let convertedFile;
                          // 파일 타입이 비디오일 경우 videos 배열에 추가
                          if (file.type.startsWith("video/")) {
                            const isSupportedVideoType =
                              file.type === "video/mp4" ||
                              file.type === "video/quicktime";
                            if (!isSupportedVideoType) {
                              setIsError(true);
                              return; // Exit the function if the video format is not supported
                            }

                            const newSrc = URL.createObjectURL(file);
                            const thumbNail =
                              await extractVideoThumbnailUrl(newSrc);
                            console.log("동영상");
                            editWorkoutItem({
                              ...item,
                              videosDetail: [
                                ...item.videosDetail,
                                {
                                  url: newSrc,
                                  thumbnail: thumbNail,
                                  id: `placeholder-${Date.now()}-${i}`,
                                  file: file,
                                },
                              ],
                            });
                          } else {
                            console.log("이미지");
                            convertedFile = await convertImageToPng(file);
                            const newSrc = URL.createObjectURL(convertedFile);
                            // 이미지 파일일 경우 images 배열에 추가
                            editWorkoutItem({
                              ...item,
                              imagesDetail: [
                                ...item.imagesDetail,
                                {
                                  url: newSrc,
                                  id: `placeholder-${Date.now()}-${i}`,
                                  thumbnail: undefined,
                                  file,
                                },
                              ],
                            });
                          }
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            }
            {data?.lessonResultType == "feedback_workout_set_weight" &&
              item.sets.length > 0 && (
                <EditableSetTable
                  workout={item}
                  editWorkoutItem={editWorkoutItem}
                />
              )}
            {data?.lessonResultType == "feedback_workout_set_weight" && (
              <Button
                css={setAddButtonCSS}
                onClick={() => {
                  const lastSet = item.sets[item.sets.length - 1];
                  const newSet = lastSet
                    ? { ...lastSet }
                    : { weight: 10, count: 10, assistant: false };

                  editWorkoutItem({ ...item, sets: [...item.sets, newSet] });
                }}
              >
                세트 추가하기
              </Button>
            )}
          </div>
        )}
      </div>
      <DialogPopup
        isOpen={isError}
        setIsOpen={(state) => {
          setIsError(state);
        }}
        title={"지원되지 않는 확장자 입니다"}
        body={"PNG, JPG, MOV, MP4 의 파일 확장자를 지원하고 있어요."}
      />

      <PrevFeedbackDialog
        isOpen={isFeedbackDialogOpen}
        setIsOpen={(state) => setIsFeedbackDialogOpen(state)}
        workout={{
          id: item.id,
          isCustom: item.isCustom,
          workoutName: item.workoutName, // 대소문자 수정 필요
          part: item.part,
          sets: item.sets,
          images: item.imagesDetail, // AttachFileDTO의 filePath로 변환
          videos: item.videosDetail,
          feedback: item.feedback || "",
          thumbnail: item.thumbnail,
        }}
        userId={data?.userId ?? 0}
      />
    </>
  );
}

const setAddButtonCSS = css`
  border-radius: 4px;
  border: 1px solid ${colors.black};
  padding: 12px 16px;
  background-color: transparent;
`;

const buttonCSS = css`
  margin-left: auto;
  background: transparent;
  color: ${colors.gray600};
  ${typography.mobile.body2};

  :hover {
    background: ${colors.gray100};
    border-radius: 8px;
  }
`;

const imageListContainerCSS = css`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const feedbackTextCSS = css`
  color: ${colors.gray800};
  ${typography.mobile.body2}
  min-width: 107px;
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
`;

const workoutNameTextCSS = css`
  color: ${colors.gray900};
  ${typography.mobile.heading2};
  width: 100%;
`;

const bodyContainerCSS = css`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const upperContainerCSS = css`
  display: flex;
`;
const badgeContainerCSS = css`
  display: flex;
  gap: 8px;
  ${typography.mobile.body2}
`;

const badgeCSS = css`
  padding: 4px 6px;
  background: ${colors.gray800};
  color: white;
  border-radius: 2px;
`;

const mainContainerCSS = css`
  padding: 30px;
  border-radius: 8px 8px 0 0;
  border: 1px solid ${colors.gray200};
  background: ${colors.gray25};
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
`;

const foldedContainerCSS = css`
  padding: 50px 40px 40px 40px;
  border-radius: 0 0 8px 8px;
  background: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const feedbackContainerCSS = css`
  display: flex;
  gap: 40px;
`;

/**
 * TODO(JUN) - 운동정보, 이전 피드백 관련 사항 추후 구현 예정
 */
const bottomButtonListContainerCSS = css`
  display: flex;
`;

const prevFeedbackCSS = css`
  padding: 20px;
  display: flex;
  justify-content: center;
  border: 1px solid ${colors.gray200};
  border-top: none;
  background: white;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
  ${typography.mobile.body2};
  align-items: center;
  gap: 8px;
`;
