import { SVGProps } from "react";

export default function IcPlus({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 5.5C12 5.22386 12.2239 5 12.5 5C12.7761 5 13 5.22386 13 5.5V18.5C13 18.7761 12.7761 19 12.5 19C12.2239 19 12 18.7761 12 18.5V5.5Z" />
      <path d="M19 11.5C19.2761 11.5 19.5 11.7239 19.5 12C19.5 12.2761 19.2761 12.5 19 12.5L6 12.5C5.72386 12.5 5.5 12.2761 5.5 12C5.5 11.7239 5.72386 11.5 6 11.5L19 11.5Z" />
    </svg>
  );
}
