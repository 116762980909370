import Button from "design-system/components/Button/Button";
import { useNavigate } from "react-router";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { isValidDate } from "../hoock/useDate";
import { css } from "@emotion/react";
import { CrmHeader } from "../common/Header/CrmHeader";
import { TextFieldContent } from "../crm/user-manage/create/component/TextFieldContent";
import { DialogPopup } from "design-system/components/DialogPopup/DialogPopup";
import {
  postOnboarding,
  RequestTrainerOnboardingRequest,
} from "../api/trainer/onboarding/onboarding";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { Textarea } from "design-system/components/Textarea/Textarea";
import { Checkbox } from "design-system/components/Checkbox/Checkbox";
import { ValidatePhoneDialog } from "./component/ValidatePhoneDialog";
import { postVerificationSms } from "../api/common/common";

export function OnboardingPage() {
  const navigate = useNavigate();
  const [isErrorInput, setIsErrorInput] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isValidateDialogOpen, setIsValidateDialogOpen] = useState(false);

  const termsURL =
    "https://fitsyou.notion.site/FitsYou-8ca2368f0436411e84926ed2b43d4a08";

  const [request, setRequest] = useState<RequestTrainerOnboardingRequest>({
    name: "",
    gender: "male",
    phone: "",
    birthDate: "",
    yearOfExperience: "one_to_two",
  });

  const { mutate: addUser } = useMutation({
    mutationFn: postOnboarding,
    onSuccess: () => {
      navigate("/crm");
    },
    onError: () => {},
  });

  const { mutate: sendSms, data } = useMutation({
    mutationFn: postVerificationSms,
    onSuccess: () => {
      setIsValidateDialogOpen(true);
    },
  });

  const signUp = () => {
    if (isValidDate(request.birthDate.replaceAll(".", "-"))) {
      addUser(request);
    } else {
      setIsErrorInput(true);
    }
  };

  return (
    <div
      css={css`
        min-height: 100vh;
        height: 100%;
      `}
    >
      <CrmHeader />
      <div css={containerCSS}>
        <div css={contentCSS}>
          <div>
            <div css={headerContainerCSS}>회원 가입</div>
            <div css={subHeaderContainerCSS}>기본 정보를 입력해주세요</div>
          </div>
          <TextFieldContent
            title={"이름"}
            placeHolder={"이름을 입력해주세요"}
            setData={(data) => {
              setRequest({
                ...request,
                ...{ name: data },
              });
            }}
          />
          <div>
            <div css={subtitleTextCSS}>생년월일</div>
            <label css={textareaWrapperCSS}>
              <div css={innerWrapperCSS}>
                <textarea
                  placeholder={"YYYY.MM.DD"}
                  css={[textAreaCSS]}
                  onChange={(event) => {
                    let value = event.target.value.replace(/[^0-9]/g, ""); // 숫자 이외 제거
                    if (value.length > 8) value = value.slice(0, 8); // 최대 8글자 제한

                    // 자동으로 "." 추가
                    if (value.length > 4)
                      value = value.slice(0, 4) + "." + value.slice(4);
                    if (value.length > 7)
                      value = value.slice(0, 7) + "." + value.slice(7);

                    event.target.value = value; // 필드 값 업데이트
                    setRequest({
                      ...request,
                      birthDate: value.replaceAll(".", "-"),
                    });
                  }}
                />
              </div>
            </label>
          </div>
          <div css={textFieldTitleTextCSS}>
            성별
            <div css={genderButtonContainerCSS}>
              <Button
                variant={"tertiary"}
                css={[
                  buttonCSS,
                  request.gender == "male" ? buttonSelectedCSS : "",
                ]}
                onClick={() => setRequest({ ...request, gender: "male" })}
              >
                남성
              </Button>
              <Button
                variant={"tertiary"}
                css={[
                  buttonCSS,
                  request?.gender == "female" ? buttonSelectedCSS : "",
                ]}
                onClick={() => setRequest({ ...request, gender: "female" })}
              >
                여성
              </Button>
            </div>
          </div>
          <div css={textFieldTitleTextCSS}>
            경력
            <div css={genderButtonContainerCSS}>
              <Button
                variant={"tertiary"}
                css={[
                  buttonCSS,
                  request.yearOfExperience == "one_to_two"
                    ? buttonSelectedCSS
                    : "",
                ]}
                onClick={() =>
                  setRequest({ ...request, yearOfExperience: "one_to_two" })
                }
              >
                1-2년차
              </Button>
              <Button
                variant={"tertiary"}
                css={[
                  buttonCSS,
                  request.yearOfExperience == "three_to_five"
                    ? buttonSelectedCSS
                    : "",
                ]}
                onClick={() =>
                  setRequest({
                    ...request,
                    yearOfExperience: "three_to_five",
                  })
                }
              >
                3-5년차
              </Button>
              <Button
                variant={"tertiary"}
                css={[
                  buttonCSS,
                  request.yearOfExperience == "six_to_ten"
                    ? buttonSelectedCSS
                    : "",
                ]}
                onClick={() =>
                  setRequest({ ...request, yearOfExperience: "six_to_ten" })
                }
              >
                6-19년차
              </Button>
              <Button
                variant={"tertiary"}
                css={[
                  buttonCSS,
                  request.yearOfExperience == "ten_over"
                    ? buttonSelectedCSS
                    : "",
                ]}
                onClick={() =>
                  setRequest({ ...request, yearOfExperience: "ten_over" })
                }
              >
                10년차 이상
              </Button>
            </div>
          </div>
          <div css={textFieldTitleTextCSS}>
            휴대전화
            <div css={phoneContainerCSS}>
              <Textarea
                isNumberInput={true}
                disabled={isValidPhoneNumber}
                onChange={(event) =>
                  setRequest({ ...request, phone: event.target.value })
                }
              />
              <Button
                disabled={isValidPhoneNumber || request.phone.length == 0}
                css={validateButtonCSS}
                onClick={() => sendSms(request.phone)}
                variant={"primary"}
                size={"auto"}
              >
                {isValidPhoneNumber ? "인증 완료" : "인증 요청"}
              </Button>
            </div>
          </div>
        </div>
        <div css={agreeContainerCSS}>
          <div
            css={css`
              display: flex;
              width: auto;
              gap: 10px;
              align-items: center;
              cursor: pointer;
            `}
            onClick={() => setIsAgreed(!isAgreed)}
          >
            <Checkbox checked={isAgreed} />
            <div
              css={css`
                color: ${colors.gray700};
                ${typography.mobile.body1}
              `}
            >
              개인정보 수집 및 이용 동의
            </div>
          </div>
          <Button
            variant={"tertiary"}
            css={termsTextCSS}
            onClick={() => window.open(termsURL, "_blank")}
          >
            약관보기
          </Button>
        </div>
        <div css={finalButtonContainerCSS}>
          <Button
            css={finalButtonCSS}
            variant={"primary"}
            onClick={signUp}
            disabled={
              !(
                !!request.name &&
                !!request.birthDate &&
                isValidPhoneNumber &&
                isAgreed
              )
            }
          >
            핏츠유 시작하기
          </Button>
        </div>
      </div>
      <ValidatePhoneDialog
        isOpen={isValidateDialogOpen}
        setIsOpen={setIsValidateDialogOpen}
        phoneNumber={request.phone}
        setIsValidPhoneNumber={setIsValidPhoneNumber}
      />
      <DialogPopup
        isOpen={isErrorInput}
        setIsOpen={setIsErrorInput}
        title={"입력 오류입니다"}
        body={"날짜 형식에 맞게 입력해주세요 'YYYY.MM.DD'"}
      />
    </div>
  );
}

const termsTextCSS = css`
  ${typography.mobile.body2};
  color: ${colors.gray700};
  background: none;
  text-decoration-line: underline;
  margin-left: auto;
`;

const agreeContainerCSS = css`
  max-width: 768px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const validateButtonCSS = css`
  width: 136px;
`;

const phoneContainerCSS = css`
  display: flex;
  gap: 10px;
`;

const finalButtonContainerCSS = css`
  max-width: 768px;
  width: 100%;
  display: flex;
  background: ${colors.lightGray};
`;

const finalButtonCSS = css`
  width: 100%;
  height: 64px;
`;

const subHeaderContainerCSS = css`
  ${typography.Body3}
`;

const headerContainerCSS = css`
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 70px */
`;

const descriptionTextCSS = css`
  ${typography.Body4}
`;

const contentCSS = css`
  padding: 60px;
  background: white;
  width: 768px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 8px;
`;

const containerCSS = css`
  background: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  gap: 57px;
  padding: 57px 0;
  align-items: center;
  min-height: 100vh;
  height: 100%;
`;

const genderButtonContainerCSS = css`
  display: flex;
  margin-top: 8px;
  gap: 10px;
  width: 100%;
`;

const textFieldTitleTextCSS = css`
  ${typography.mobile.body1};
  color: ${colors.gray600};
`;

const buttonSelectedCSS = css`
  width: 100%;
  height: 48px;
  color: ${colors.lime900};
  background: rgba(224, 245, 105, 0.12);
  border: solid 1px ${colors.lime900};
`;
const buttonCSS = css`
  width: 100%;
  height: 48px;
  background: white;
  border: solid 1px ${colors.gray100};
  color: ${colors.gray200};
`;

const textareaWrapperCSS = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11px 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${colors.default.Gray600};
  background-color: white;
`;

const innerWrapperCSS = css`
  display: flex;
  align-items: center;
  width: 100%;
`;

const textAreaCSS = css`
  display: flex;
  width: 100%;
  border: 0;
  padding: 0;
  height: 20px;
  resize: none;
  background-color: transparent;
  color: ${colors.gray800};
  ${typography.mobile.body2};

  ::placeholder {
    color: ${colors.default.Gray300};
  }

  :disabled {
    cursor: not-allowed;
    color: ${colors.default.Gray700};
  }
`;

const subtitleTextCSS = css`
  color: ${colors.gray700};
  ${typography.mobile.body2}
`;
