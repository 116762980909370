import { css } from "@emotion/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useLocation, useNavigate } from "react-router";
import { CustomError, CustomFetchError } from "../../libs/fetch/fetch";
import { ERROR_MAP, ErrorCode } from "../../constants/errors";
import { resetErrorQueries } from "../../libs/react-query";
import { typography } from "design-system/styles/typography/typography";
import Button from "design-system/components/Button/Button";
import { colors } from "design-system/styles/colors";
import { CrmHeader } from "../Header/CrmHeader";
import infoImage from "design-system/components/atom/IconResource/info.png";

interface ErrorTemplateInterface {
  error: unknown;
}

class ErrorDataInterface {}

export default function ErrorTemplate({ error }: ErrorTemplateInterface) {
  // TODO : 상세 디자인 확정시, errorData를 활용하도록 수정이 필요합니다. - @Matt
  const [, setErrorData] = useState<ErrorDataInterface>();
  const [isSessionError, setIsSessionError] = useState(false);
  const { resetBoundary } = useErrorBoundary();
  const { key } = useLocation();
  const [initialLocationKey] = useState(key);
  const navigate = useNavigate();

  const errorCode = useMemo(() => {
    const fetchError = error as CustomFetchError;
    const customError = fetchError.data as CustomError;

    if (customError?.code) return customError.code;
    if (fetchError?.status) return fetchError.status;

    return null;
  }, [error]);

  const handleFetchError = useCallback(() => {
    const fetchError = error as CustomFetchError;

    if (!fetchError?.status) return;

    if (fetchError.status === 401) {
      setIsSessionError(true);
    }

    setErrorData(ERROR_MAP.get(fetchError.status));

    return true;
  }, [error]);

  const handleCustomError = useCallback(() => {
    const customError = error as CustomError;

    if (!customError?.code) return;

    setErrorData(ERROR_MAP.get(customError.code));

    return true;
  }, [error]);

  const handleFunctionList = useMemo(
    () => [handleFetchError, handleCustomError],
    [handleFetchError, handleCustomError],
  );

  useEffect(() => {
    for (const handleFn of handleFunctionList) {
      if (handleFn()) break;
    }
  }, [handleFunctionList]);

  useEffect(() => {
    if (initialLocationKey !== key) {
      resetBoundary();
      resetErrorQueries();
    }
  }, [initialLocationKey, key, resetBoundary]);

  return (
    <>
      <CrmHeader logoutDisable={true} />
      <div css={errorPageCSS}>
        {/*<PfFail width={40} height={40} color={colors.orange} />*/}
        <img src={infoImage} css={infoImageCSS} />
        {errorCode == ErrorCode.SESSION ? (
          <>
            <h3 css={errorTitleCSS}>로그인이 필요한 화면이에요</h3>
            <span css={errorTextCSS}>재로그인 해주세요</span>
          </>
        ) : (
          <>
            <h3 css={errorTitleCSS}>오류가 발생했어요</h3>
            <span css={errorTextCSS}>잠시후 다시 시도해 주세요</span>
          </>
        )}

        <Button
          variant={"primary"}
          size={46}
          styles={errorButtonCSS}
          onClick={() => navigate("/")}
        >
          홈으로 이동
        </Button>
      </div>
      {/*{isSessionError && <SessionErrorDialog />}*/}
    </>
  );
}
const infoImageCSS = css`
  height: 40px;
  width: 40px;
  object-fit: contain;
`;

const errorPageCSS = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  background: ${colors.gray25};
`;

const errorTitleCSS = css`
  margin-top: 32px;
  ${typography.Body3};
`;

const errorTextCSS = css`
  margin-top: 8px;
  ${typography.Body2};
  text-align: center;
  ${typography.Button1};
  color: ${colors.gray400};
`;

const errorButtonCSS = css`
  margin-top: 32px;
  width: 312px;
`;
