import { useMutation, useQuery } from "@tanstack/react-query";
import { getUsersUserId, postUsersNew } from "../../../api/trainer/users/users";
import { queryClient, queryKeys } from "../../../libs/react-query";
import { useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import { CrmHeader } from "../../../common/Header/CrmHeader";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { RequestSaveTrainerUserRequestDTO } from "../../../api/trainer/users/usersTypes";
import React, { useEffect, useState } from "react";
import Button from "design-system/components/Button/Button";
import { TextFieldContent } from "./component/TextFieldContent";
import { TicketDateInput } from "./component/TicketDateInput";
import { useNavigate } from "react-router";
import { isValidDate } from "../../../hoock/useDate";
import { DialogPopup } from "design-system/components/DialogPopup/DialogPopup";

export function CreateTicketPage() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const userId = parseInt(searchParams.get("userId") ?? "0");
  const navigate = useNavigate();

  const [isErrorInput, setIsErrorInput] = useState(false);

  const { data: userInfo } = useQuery({
    queryFn: () => getUsersUserId(userId),
    queryKey: queryKeys.getUsersUserId(userId),
    enabled: !!userId && userId !== 0,
  });

  const [emailRequest, setEmailRequest] =
    useState<RequestSaveTrainerUserRequestDTO>({
      email: userInfo?.email ?? "",
      userId: userInfo?.userId ?? 0,
      ticket: {
        totalCount: 0,
        startAt: "",
      },
    });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { mutate: addUser } = useMutation({
    mutationFn: postUsersNew,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getUsers());
      navigate("/crm/user-manage");
    },
    onError: () => {},
  });

  const registerTicket = () => {
    if (
      isValidDate(emailRequest.ticket.startAt.replaceAll(".", "-")) &&
      (emailRequest.ticket.endAt == undefined ||
        isValidDate(emailRequest.ticket.endAt.replaceAll(".", "-")))
    ) {
      addUser(emailRequest);
    } else {
      setIsErrorInput(true);
    }
  };

  return (
    <div
      css={css`
        min-height: 100vh;
        height: 100%;
      `}
    >
      <CrmHeader />
      <div css={containerCSS}>
        <div css={contentCSS}>
          <div css={headerContainerCSS}>
            회원 정보
            <RequireDescription />
          </div>
          <TextFieldContent
            isRequired={true}
            title={"이름"}
            isEditable={false}
            placeHolder={"이름을 입력해주세요"}
            defaultData={userInfo?.name}
            setData={(data) => {
              setEmailRequest({
                ...emailRequest,
                ...{ name: data },
              });
            }}
          />
          <TextFieldContent
            isRequired={false}
            title={"생년월일"}
            isEditable={false}
            placeHolder={"YYYY.MM.DD"}
            defaultData={userInfo?.birthdate}
            setData={(data) => {
              setEmailRequest({
                ...emailRequest,
                ...{ birthdate: data },
              });
            }}
          />

          <TextFieldContent
            isRequired={true}
            title={"휴대전화"}
            isEditable={false}
            placeHolder={"휴대전화 번호를 기입해주세요"}
            description={
              "회원앱을 설치하지 않은 수강생님께 카톡으로 일지를 전송할 수 있어요"
            }
            defaultData={userInfo?.phone}
            setData={(data) => {
              setEmailRequest({
                ...emailRequest,
                ...{ birthdate: data },
              });
            }}
          />
          <div css={textFieldTitleTextCSS}>
            성별
            <div css={genderButtonContainerCSS}>
              <Button
                disabled={true}
                variant={"tertiary"}
                css={[
                  buttonCSS,
                  userInfo?.gender == "male" ? buttonSelectedCSS : "",
                ]}
              >
                남성
              </Button>
              <Button
                disabled={true}
                variant={"tertiary"}
                css={[
                  buttonCSS,
                  userInfo?.gender == "female" ? buttonSelectedCSS : "",
                ]}
              >
                {" "}
                여성
              </Button>
            </div>
          </div>
        </div>
        <div css={contentCSS}>
          <div css={headerContainerCSS}>
            수강권 정보
            <RequireDescription />
          </div>
          <TextFieldContent
            title={"수강권 횟수"}
            isRequired={true}
            placeHolder={"수강권 횟수를 입력해주세요"}
            description={"이미 진행한 수업일 경우 잔여 회차를 입력해주세요"}
            setData={(value) =>
              setEmailRequest({
                ...emailRequest,
                ticket: { ...emailRequest.ticket, totalCount: Number(value) },
              })
            }
          />
          <TicketDateInput
            setEndDate={(date) =>
              setEmailRequest({
                ...emailRequest,
                ticket: {
                  ...emailRequest.ticket,
                  endAt:
                    date.length == 0 ? undefined : date.replaceAll(".", "-"),
                },
              })
            }
            setStartDate={(date) =>
              setEmailRequest({
                ...emailRequest,
                ticket: {
                  ...emailRequest.ticket,
                  startAt: date.replaceAll(".", "-"),
                },
              })
            }
          />
        </div>
        <div css={contentCSS}>
          <div css={headerContainerCSS}>센터 정보</div>
          <TextFieldContent
            title={"센터 이름"}
            placeHolder={"센터 이름을 입력해주세요"}
            setData={(value) =>
              setEmailRequest({
                ...emailRequest,
                ticket: { ...emailRequest.ticket, centerName: value },
              })
            }
          />
          <TextFieldContent
            title={"센터 전화번호"}
            placeHolder={"센터 전화번호를 입력해주세요"}
            isNumberInput={true}
            setData={(value) =>
              setEmailRequest({
                ...emailRequest,
                ticket: { ...emailRequest.ticket, centerTel: value },
              })
            }
          />

          <TextFieldContent
            title={"결제금액"}
            placeHolder={"결재금액을 입력해주세요"}
            isNumberInput={true}
            setData={(value) =>
              setEmailRequest({
                ...emailRequest,
                ticket: { ...emailRequest.ticket, price: Number(value) },
              })
            }
          />

          <TextFieldContent
            title={"기타내용"}
            description={"회원님께는 노출되지 않는 내용이에요"}
            placeHolder={"메모가 필요한 내용을 적어주세요"}
            setData={(value) =>
              setEmailRequest({
                ...emailRequest,
                ticket: { ...emailRequest.ticket, otherInfo: value },
              })
            }
          />
        </div>
        <div css={finalButtonContainerCSS}>
          <Button
            css={finalButtonCSS}
            variant={"primary"}
            onClick={registerTicket}
            disabled={
              !(
                !!emailRequest.ticket.startAt &&
                !!emailRequest.ticket.totalCount
              )
            }
          >
            수강권 추가하기
          </Button>
        </div>
      </div>
      <DialogPopup
        isOpen={isErrorInput}
        setIsOpen={setIsErrorInput}
        title={"입력 오류입니다"}
        body={"날짜 형식에 맞게 입력해주세요 'YYYY.MM.DD'"}
      />
    </div>
  );
}

export const RequireDescription = () => {
  return (
    <div css={descriptionTextCSS}>
      <span
        css={css`
          color: red;
        `}
      >
        *
      </span>
      는 필 수 입력칸입니다
    </div>
  );
};
const finalButtonContainerCSS = css`
  width: 768px;
  display: flex;
  background: ${colors.lightGray};
`;

const finalButtonCSS = css`
  width: 100%;
  height: 64px;
`;

const buttonSelectedCSS = css`
  width: 100%;
  height: 48px;
  color: ${colors.lime900};
  background: rgba(224, 245, 105, 0.12);
  border: solid 1px ${colors.lime900};
`;
const buttonCSS = css`
  width: 100%;
  height: 48px;
  background: white;
  border: solid 1px ${colors.gray100};
`;

const genderButtonContainerCSS = css`
  display: flex;
  margin-top: 8px;
  gap: 10px;
`;

const textFieldTitleTextCSS = css`
  ${typography.mobile.body1};
  color: ${colors.gray600};
`;

const headerContainerCSS = css`
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 70px */
`;

const descriptionTextCSS = css`
  ${typography.Body4}
`;

const contentCSS = css`
  padding: 60px;
  background: white;
  width: 768px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 8px;
`;

const containerCSS = css`
  background: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  gap: 57px;
  padding: 57px 0;
  align-items: center;
  min-height: 100vh;
  height: 100%;
`;
