import { customFetch } from "../../../libs/fetch/fetch";
import { Gender, YearsOfExperience } from "../../common/commonEnumType";

export async function postOnboarding({
  ...request
}: RequestTrainerOnboardingRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/onboarding`,
    },
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}

export type RequestTrainerOnboardingRequest = {
  name: string;
  birthDate: string;
  gender: Gender;
  yearOfExperience: YearsOfExperience;
  phone: string;
};
