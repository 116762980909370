import { customFetch } from "../../libs/fetch/fetch";
import {
  GetWorkoutsResponse,
  PostUploadsPresignedRequest,
  PostUploadsPresignedResponse,
  PostWorkoutCustomRequest,
} from "./commonTypes";

export async function postUploadsPresigned({
  ...request
}: PostUploadsPresignedRequest): Promise<PostUploadsPresignedResponse> {
  return await customFetch(
    {
      app: "common",
      version: "v1",
      path: `/uploads/presigned`,
      params: { ...request },
    },
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function getWorkouts(): Promise<GetWorkoutsResponse> {
  return await customFetch({
    app: "common",
    version: "v1",
    path: `/workouts`,
  });
}

export async function postWorkoutCustom({
  ...request
}: PostWorkoutCustomRequest): Promise<void> {
  return await customFetch(
    {
      app: "common",
      version: "v1",
      path: `/workouts/custom`,
    },
    {
      method: "POST",
      body: JSON.stringify({
        ...request,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function postVerification(code: string): Promise<void> {
  return await customFetch(
    {
      app: "common",
      version: "v1",
      path: `/verification`,
      params: { code },
    },
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function postVerificationSms(phone: string): Promise<void> {
  return await customFetch(
    {
      app: "common",
      version: "v1",
      path: `/verification/sms`,
      params: { phone },
    },
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}
