import logo from "../../resource/trainee/bottomLogo.svg";
import {css} from "@emotion/react";
import {useWindowSize} from "../../hoock/useWindowSize";
import {getWidthMediaQuery} from "../../styles/mediaQueries";
import {fontFamily, fontWeight,} from "design-system/styles/typography/typography";

/** @jsxImportSource @emotion/react */
export function Frame4() {
  const { isMobile, isPC } = useWindowSize();
  return (
    <div css={backgroundContainerCSS}>
      {/*<div css={imageContainerCSS}>*/}
      {/*  {isPC && <img src={bg} alt={"FitsYouBelt"} css={backgroundCSS} />}*/}
      {/*  {isMobile && (*/}
      {/*    <img src={bgMobile} alt={"FitsYouBeltMobile"} css={backgroundCSS} />*/}
      {/*  )}*/}
      {/*</div>*/}
      <div css={contentContainerCSS}>
        <img src={logo} alt={""} css={imageCSS} />
        <span css={messageText1CSS}>Perfect Trainer</span>
        <span css={messageText2CSS}>Perfect Fitness</span>
        <span css={messageText3CSS}>by FitsYou</span>
      </div>
    </div>
  );
}

const imageCSS = css`
  width: 190px;
  height: 190px;

  ${getWidthMediaQuery("pc")} {
    width: 18.75vw;
    height: 18.75vw;
  }
`;

const messageText3CSS = css`
  color: #fff;
  font-size: 25px;
  margin-top: 35px;
  font-weight: ${fontWeight.Bold};
  font-family: ${fontFamily.natomPro}, sans-serif;
  line-height: 120%;
  letter-spacing: -0.25px;

  ${getWidthMediaQuery("pc")} {
    font-size: 2.6vw;
    margin-top: 6.25vw;
  }
`;

const messageText1CSS = css`
  text-align: center;
  font-family: ${fontFamily.natomPro}, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 259.2px */
  letter-spacing: -2.16px;
  background: linear-gradient(101deg, #fff -10.81%, #000 104.61%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 11vw;
`;
const messageText2CSS = css`
  font-family: ${fontFamily.natomPro}, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -1.6px;
  background: linear-gradient(101deg, #fff -10.81%, #000 104.61%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 8vw;
`;

const contentContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${getWidthMediaQuery("pc")} {
    top: 250px;
  }
`;

const imageContainerCSS = css`
  width: 100%;
  height: 100%;
`;

const backgroundCSS = css`
  width: 100%;
  height: 100%;
`;
const backgroundContainerCSS = css`
  display: flex;
  flex-direction: column;
`;
