import { customFetch } from "../../../libs/fetch/fetch";
import {
  GetUsersNewSearchResponse,
  GetUsersResponse,
  GetUsersUserIdLessonsLessonIdRequest,
  GetUsersUserIdLessonsLessonIdResponse,
  GetUsersUserIdResponse,
  GetUsersUserIdWorkoutsWorkoutIdRequest,
  GetUsersUserIdWorkoutsWorkoutIdResponse,
  PostUsersNewGuestRequest,
  PostUsersNewRequest,
} from "./usersTypes";

export async function getUsersUserIdLessonsLessonId({
  userId,
  lessonId,
}: GetUsersUserIdLessonsLessonIdRequest): Promise<GetUsersUserIdLessonsLessonIdResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/users/${userId}/lessons/${lessonId}`,
  });
}

export async function getUsersUserIdWorkoutsWorkoutId({
  userId,
  workoutId,
  isCustom,
}: GetUsersUserIdWorkoutsWorkoutIdRequest): Promise<GetUsersUserIdWorkoutsWorkoutIdResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/users/${userId}/workouts/${workoutId}`,
    params: { isCustom },
  });
}

export async function getUsersUserId(
  userId: number,
): Promise<GetUsersUserIdResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/users/${userId}`,
    params: { userId },
  });
}

export async function getUsers(): Promise<GetUsersResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/users`,
  });
}

export async function getUsersNewSearch(
  email: string,
): Promise<GetUsersNewSearchResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/users/new/search`,
    params: { email },
  });
}

export async function postUsersNew({
  ...request
}: PostUsersNewRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/users/new`,
    },
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}

export async function postUsersNewGuest({
  ...request
}: PostUsersNewGuestRequest): Promise<void> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/users/new/guest`,
    },
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}
