import * as Dialog from "design-system/components/Dialog/Dialog";
import closeIcon from "design-system/components/atom/IconResource/ic_close.svg";
import React, { useState } from "react";
import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { useMutation } from "@tanstack/react-query";
import { postWorkoutCustom } from "../../../../../api/common/common";
import Button from "design-system/components/Button/Button";
import { Textarea } from "design-system/components/Textarea/Textarea";
import { WorkoutParts } from "../../../../../api/common/commonEnumType";
import * as RadioGroup from "design-system/components/RadioGroup/RadioGroup";
import { queryClient, queryKeys } from "../../../../../libs/react-query";

export interface AddCustomWorkoutDialogProps {
  isAddCustomDialogOpen: boolean;
  setIsAddCustomDialogOpen: (state: boolean) => void;
}

export function AddCustomWorkoutDialog({
  isAddCustomDialogOpen,
  setIsAddCustomDialogOpen,
}: AddCustomWorkoutDialogProps) {
  const [workoutPart, setWorkoutPart] = useState<WorkoutParts>("lower_body");
  const [name, setName] = useState("");

  const { mutate: sendReportMutate, isLoading } = useMutation({
    mutationFn: postWorkoutCustom,
    onSuccess: () => {
      setIsAddCustomDialogOpen(false);
      queryClient.invalidateQueries(queryKeys.getWorkouts());
      setWorkoutPart("lower_body");
      setName("");
    },
  });

  return (
    <Dialog.Root variant={"page"} open={isAddCustomDialogOpen}>
      <Dialog.Content>
        <div css={dialogHeaderCSS}>
          <div css={dialogHeaderTextCSS}>커스텀 운동 추가</div>
          <img
            src={closeIcon}
            css={closeIconCSS}
            onClick={() => {
              setIsAddCustomDialogOpen(false);
            }}
          />
        </div>
        <div css={dialogBodyContainerCSS}>
          <Textarea
            defaultValue={name}
            placeholder={"추가할 운동 이름을 작성해주세요"}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />

          <RadioGroup.Root
            defaultValue={workoutPart}
            onValueChange={(part: WorkoutParts) => {
              setWorkoutPart(part);
            }}
            styles={labelComponentListContainerCSS}
          >
            <span css={partTitleTextCSS}>운동 부위</span>
            <RadioGroup.ItemWithLabel value="lower_body">
              <div>하체</div>
            </RadioGroup.ItemWithLabel>
            <RadioGroup.ItemWithLabel value="chest">
              <div>가슴</div>
            </RadioGroup.ItemWithLabel>
            <RadioGroup.ItemWithLabel value="back">
              <div>등</div>
            </RadioGroup.ItemWithLabel>
            <RadioGroup.ItemWithLabel value="shoulders">
              <div>어깨</div>
            </RadioGroup.ItemWithLabel>
            <RadioGroup.ItemWithLabel value="arms">
              <div>팔</div>
            </RadioGroup.ItemWithLabel>
            <RadioGroup.ItemWithLabel value="abs">
              <div>복근</div>
            </RadioGroup.ItemWithLabel>
            <RadioGroup.ItemWithLabel value="etc">
              <div>기타</div>
            </RadioGroup.ItemWithLabel>
          </RadioGroup.Root>
        </div>
        <div css={saveButtonContainerCSS}>
          <Button
            variant={"primary"}
            css={saveButtonCSS}
            isLoading={isLoading}
            disabled={name.length == 0}
            onClick={() => {
              sendReportMutate({
                workoutName: name,
                workoutPart: workoutPart,
              });
            }}
          >
            커스텀 운동 추가하기
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

const labelComponentListContainerCSS = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const dialogBodyContainerCSS = css`
  padding: 20px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const partTitleTextCSS = css`
  ${typography.mobile.body1}
`;

const saveButtonCSS = css`
  width: 100%;
  padding: 12px 16px;
`;

const saveButtonContainerCSS = css`
  padding: 24px;
  background-color: white;
  display: flex;
  gap: 10px;
`;

const dialogHeaderTextCSS = css`
  width: 100%;
  text-align: center;
  ${typography.mobile.heading3}
`;

const closeIconCSS = css`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const dialogHeaderCSS = css`
  display: flex;
  padding: 14px;
`;
