import * as Dialog from "design-system/components/Dialog/Dialog";
import { Textarea } from "design-system/components/Textarea/Textarea";
import Button from "design-system/components/Button/Button";
import React, { useState } from "react";
import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";
import { getUsersNewSearch } from "../../../../api/trainer/users/users";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../../libs/react-query";
import { useNavigate } from "react-router";

interface AddEmailDialogProps {
  isEmailInputOpen: boolean;
  setIsEmailInputOpen: (state: boolean) => void;
  setIsEmailInputError: (state: boolean) => void;
}

export function AddMemberByEmailDialog({
  isEmailInputOpen,
  setIsEmailInputOpen,
  setIsEmailInputError,
}: AddEmailDialogProps) {
  const navigate = useNavigate();
  const [inputEmail, setInputEmail] = useState("");

  const { data, isError, refetch } = useQuery({
    queryFn: () => getUsersNewSearch(inputEmail),
    queryKey: queryKeys.getUsersNewSearch(inputEmail),
    useErrorBoundary: false,
    enabled: false,
    onError: (error) => {
      console.log(error);
      setIsEmailInputError(true);
    },
    onSuccess: (userData) => {
      navigate(
        `/crm/user-manage/create?email=${inputEmail}&userId=${userData?.userId}`,
      );
    },
  });

  const onAddButtonClick = async () => {
    setIsEmailInputOpen(false);
    const userData = await refetch();
  };

  return (
    <Dialog.Root open={isEmailInputOpen}>
      <Dialog.Content css={dialogContentCSS}>
        <div css={headerContainerCSS}>
          <div>이메일 추가</div>
          <div css={subTitleTextCSS}>
            이메일은 회원앱 “마이”탭에 기재되어 있습니다
          </div>
        </div>
        <div css={textAreaContainerCSS}>
          <Textarea
            styles={textAreaCSS}
            isPreventNewline={true}
            placeholder={"회원님의 이메일을 입력해주세요"}
            onChange={(event) => {
              setInputEmail(event.target.value);
            }}
          />
        </div>
        <div css={buttonContainerCSS}>
          <Button
            onClick={() => setIsEmailInputOpen(false)}
            variant={"secondary"}
            size={46}
            css={buttonCSS}
          >
            취소
          </Button>
          <Button
            variant={"primary"}
            size={46}
            css={buttonCSS}
            onClick={onAddButtonClick}
          >
            추가하기
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

const headerContainerCSS = css`
  padding: 14px 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${typography.mobile.heading3};
  width: 100%;
  text-align: center;
`;

const subTitleTextCSS = css`
  margin-top: 15px;
  ${typography.mobile.body2};
  color: ${colors.gray600};
`;

const textAreaCSS = css`
  width: 100%;
  height: 48px;
`;

const textAreaContainerCSS = css`
  padding: 20px 24px;
  width: 100%;
`;

const buttonCSS = css`
  width: 100%;
`;

const buttonContainerCSS = css`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 24px;
  width: 100%;
`;

const dialogContentCSS = css`
  width: 568px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
