import * as Dialog from "design-system/components/Dialog/Dialog";
import { css } from "@emotion/react";
import { Textarea } from "design-system/components/Textarea/Textarea";
import Button from "design-system/components/Button/Button";
import React, { useEffect, useState } from "react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import closeImg from "design-system/components/atom/IconResource/ic_close.svg";
import timeImg from "design-system/components/atom/IconResource/ic_time.svg";
import { useMutation } from "@tanstack/react-query";
import { postVerification, postVerificationSms } from "../../api/common/common";
import { DialogPopup } from "design-system/components/DialogPopup/DialogPopup";
import { CustomFetchError } from "../../libs/fetch/fetch";

export type ValidatePhoneDialogProps = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  phoneNumber: string;
  setIsValidPhoneNumber: (state: boolean) => void;
};

export function ValidatePhoneDialog({
  isOpen,
  setIsOpen,
  phoneNumber,
  setIsValidPhoneNumber,
}: ValidatePhoneDialogProps) {
  const [remainingTime, setRemainingTime] = useState(180);
  const [code, setCode] = useState("");
  const [isWrongCodePopupOpen, setIsWrongCodePopupOpen] = useState(false);
  const [isAlreadySignupPopupOpen, setIsAlreadySignupPopupOpen] =
    useState(false);
  const [isExceedRequestPopupOpen, setIsExceedRequestPopupOpen] =
    useState(false);

  useEffect(() => {
    if (!isOpen) {
      return; // Dialog가 닫혀 있으면 타이머 작동 중지
    }

    if (remainingTime <= 0) {
      setIsOpen(false);
      return;
    }

    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(timer); // 컴포넌트 언마운트 시 타이머 정리
  }, [remainingTime, setIsOpen, isOpen]);

  const { mutate } = useMutation({
    mutationFn: postVerificationSms,
    onSuccess: () => {},
    useErrorBoundary: false,
    onError: (error: CustomFetchError) => {
      if (error.data?.code === 400114) {
        setIsExceedRequestPopupOpen(true);
      } else if (error.data?.code === 400118) {
        setIsAlreadySignupPopupOpen(true);
      }
    },
  });

  const { mutate: endVerification } = useMutation({
    mutationFn: postVerification,
    onSuccess: () => {
      setIsOpen(false);
      setIsValidPhoneNumber(true);
    },
    useErrorBoundary: false,
    onError: (error) => {
      console.log(error);
      setIsWrongCodePopupOpen(true);
    },
  });

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div>
      <Dialog.Root open={isOpen}>
        <Dialog.Content
          css={css`
            width: 570px;
          `}
        >
          <div css={headerContainerCSS} onClick={() => setIsOpen(false)}>
            <img src={closeImg} />
          </div>
          <div css={phoneContainerCSS}>
            <div css={validatePhoneBodyContainerCSS}>
              <div css={textFieldTitleTextCSS}>
                휴대전화
                <div css={resendContainerCSS}>
                  <Textarea
                    disabled={true}
                    defaultValue={phoneNumber}
                    value={phoneNumber}
                    css={css`
                      width: 100%;
                    `}
                  />
                  <Button
                    css={resendButtonCSS}
                    variant={"secondary"}
                    size={"auto"}
                    onClick={() => {
                      mutate(phoneNumber);
                      setRemainingTime(180);
                    }}
                  >
                    재전송
                  </Button>
                </div>
              </div>
              <div css={textFieldTitleTextCSS}>
                <div>인증번호</div>
                <Textarea onChange={(event) => setCode(event.target.value)} />
                <div css={restTimeContainerCSS}>
                  <img src={timeImg} />
                  <div>남은 시간</div>
                  <div css={restTImeTextCSS}>{formatTime(remainingTime)}</div>
                </div>
              </div>
              <Button
                variant={"primary"}
                size={46}
                onClick={() => endVerification(code)}
              >
                인증 완료
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
      <DialogPopup
        isOpen={isWrongCodePopupOpen}
        setIsOpen={setIsWrongCodePopupOpen}
        title={"인증 실패"}
        body={"잘못된 인증번호에요. 재시도 해주세요"}
      />
      <DialogPopup
        isOpen={isAlreadySignupPopupOpen}
        setIsOpen={setIsAlreadySignupPopupOpen}
        title={"동일한 번호르 가입된 이력이 있어요"}
        body={"추가문의는 고객센터를 이용해주세요(070-7954-6956)"}
        onCloseAction={() => setIsOpen(false)}
      />
      <DialogPopup
        isOpen={isExceedRequestPopupOpen}
        setIsOpen={setIsExceedRequestPopupOpen}
        title={"최대 시도 제한을 초과했어요"}
        body={"추가문의는 고객센터를 이용해주세요(070-7954-6956)"}
        onCloseAction={() => setIsOpen(false)}
      />
    </div>
  );
}

const restTImeTextCSS = css`
  margin-left: 10px;
  color: red;
`;

const restTimeContainerCSS = css`
  padding: 8px 16px;
  background: ${colors.lightGray};
  display: flex;
  gap: 4px;
  border-radius: 8px;
`;

const resendContainerCSS = css`
  display: flex;
  gap: 10px;
`;

const headerContainerCSS = css`
  padding: 14px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  border-bottom: 1px solid ${colors.gray50};
`;

const phoneContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const resendButtonCSS = css`
  background: ${colors.gray900};
  color: white;
  width: 136px;
`;

const validatePhoneBodyContainerCSS = css`
  padding: 20px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const textFieldTitleTextCSS = css`
  ${typography.mobile.body1};
  color: ${colors.gray600};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
