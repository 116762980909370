import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";
import { Checkbox } from "design-system/components/Checkbox/Checkbox";
import { useState } from "react";

export interface TicketDateInputProps {
  setEndDate: (endDate: string) => void;
  setStartDate: (startDate: string) => void;
}

export function TicketDateInput({
  setEndDate,
  setStartDate,
}: TicketDateInputProps) {
  const [isForever, setIsForEver] = useState(false);

  return (
    <div css={textFieldContainerCSS}>
      <div css={textFieldTitleTextCSS}>
        수강권 이용기간
        <span
          css={css`
            color: red;
          `}
        >
          *
        </span>
      </div>
      <div css={textInputContainerCSS}>
        <div css={dateInputContainerCSS}>
          <div css={subtitleTextCSS}>시작일</div>
          <label css={textareaWrapperCSS}>
            <div css={innerWrapperCSS}>
              <textarea
                placeholder={"YYYY.MM.DD"}
                css={[textAreaCSS]}
                onChange={(event) => {
                  let value = event.target.value.replace(/[^0-9]/g, ""); // 숫자 이외 제거
                  if (value.length > 8) value = value.slice(0, 8); // 최대 8글자 제한

                  // 자동으로 "." 추가
                  if (value.length > 4)
                    value = value.slice(0, 4) + "." + value.slice(4);
                  if (value.length > 7)
                    value = value.slice(0, 7) + "." + value.slice(7);

                  event.target.value = value; // 필드 값 업데이트
                  setStartDate(value);
                }}
              />
            </div>
          </label>
        </div>
        <div css={dateInputContainerCSS}>
          <div css={subtitleTextCSS}>종료일</div>
          <label css={textareaWrapperCSS}>
            <div css={innerWrapperCSS}>
              <textarea
                disabled={isForever}
                placeholder={"YYYY.MM.DD"}
                css={[textAreaCSS]}
                onChange={(event) => {
                  let value = event.target.value.replace(/[^0-9]/g, ""); // 숫자 이외 제거
                  if (value.length > 8) value = value.slice(0, 8); // 최대 8글자 제한

                  // 자동으로 "." 추가
                  if (value.length > 4)
                    value = value.slice(0, 4) + "." + value.slice(4);
                  if (value.length > 7)
                    value = value.slice(0, 7) + "." + value.slice(7);

                  event.target.value = value; // 필드 값 업데이트
                  setEndDate(value);
                }}
              />
            </div>
          </label>
        </div>
      </div>
      <div
        css={checkBoxContainerCSS}
        onClick={() => {
          if (!isForever) {
            setEndDate("");
          }
          setIsForEver(!isForever);
        }}
      >
        <Checkbox checked={isForever} />
        <div> 종료 제한 없음</div>
      </div>
    </div>
  );
}

const checkBoxContainerCSS = css`
  display: flex;
  gap: 10px;
  ${typography.mobile.body1};
  color: ${colors.gray700};
  align-items: center;
  cursor: pointer;
`;

const textareaWrapperCSS = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11px 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${colors.default.Gray600};
  background-color: white;
`;

const innerWrapperCSS = css`
  display: flex;
  align-items: center;
  width: 100%;
`;

const textAreaCSS = css`
  display: flex;
  width: 100%;
  border: 0;
  padding: 0;
  height: 20px;
  resize: none;
  background-color: transparent;
  color: ${colors.gray800};
  ${typography.mobile.body2};

  ::placeholder {
    color: ${colors.default.Gray300};
  }

  :disabled {
    cursor: not-allowed;
    color: ${colors.default.Gray700};
  }
`;

const subtitleTextCSS = css`
  color: ${colors.gray700};
  ${typography.mobile.body2}
`;

const dateInputContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const textInputContainerCSS = css`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  gap: 8px;
  background: ${colors.lightGray};
`;

const textFieldContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const textFieldTitleTextCSS = css`
  ${typography.mobile.body1};
  color: ${colors.gray600};
`;
const textFieldDescriptionTextCSS = css`
  ${typography.mobile.body2}
`;
