import { colors } from "design-system/styles/colors";
import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { MixedWorkoutDetail } from "../../../../../api/trainer/schedules/schedulesTypes";
import Button from "design-system/components/Button/Button";
import IcClose from "design-system/components/atom/IconResource/Icon/IcClose";
import { Textarea } from "design-system/components/Textarea/Textarea";
import { Checkbox } from "design-system/components/Checkbox/Checkbox";

type WorkoutSetTableProps = {
  workout: MixedWorkoutDetail;
  editWorkoutItem: (value: MixedWorkoutDetail) => void;
};

export function EditableSetTable({
  workout,
  editWorkoutItem,
}: WorkoutSetTableProps) {
  return (
    <div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ backgroundColor: colors.gray200 }} css={titleTextCSS}>
            <th style={{ padding: "0px" }}></th>
            <th style={{ padding: "10px" }}>세트</th>
            <th style={{ padding: "10px" }}>무게(kg)</th>
            <th style={{ padding: "10px" }}>횟수</th>
            <th style={{ padding: "10px" }}>보조</th>
          </tr>
        </thead>
        <tbody>
          {workout.sets.map((row, index) => (
            <tr key={index} css={[titleTextCSS, contentCSS]}>
              <td
                style={{
                  padding: "14px",
                  width: "10px",
                }}
              >
                <Button
                  css={eraseButtonCSS}
                  onClick={() => {
                    const updatedSets = workout.sets.filter(
                      (_, i) => i !== index,
                    );

                    // 업데이트된 객체를 editWorkoutItem으로 전달
                    editWorkoutItem({
                      ...workout,
                      sets: updatedSets,
                    });
                  }}
                >
                  <IcClose css={iconCSS} />
                </Button>
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                <div>{index + 1 + "set"}</div>
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                <Textarea
                  css={inputCSS}
                  value={row.weight}
                  onChange={(event) => {
                    editWorkoutItem({
                      ...workout,
                      sets: workout.sets.map((set, innerIndex) =>
                        index === innerIndex
                          ? { ...set, weight: Number(event.target.value) }
                          : set,
                      ),
                    });
                  }}
                />
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                <Textarea
                  css={inputCSS}
                  value={row.count}
                  onChange={(event) => {
                    editWorkoutItem({
                      ...workout,
                      sets: workout.sets.map((set, innerIndex) =>
                        index === innerIndex
                          ? { ...set, count: Number(event.target.value) }
                          : set,
                      ),
                    });
                  }}
                />
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                <Checkbox
                  checked={row.assistant}
                  onCheckedChange={(value) =>
                    editWorkoutItem({
                      ...workout,
                      sets: workout.sets.map((set, innerIndex) =>
                        index === innerIndex
                          ? { ...set, assistant: !!value }
                          : set,
                      ),
                    })
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const inputCSS = css`
  text-align: center;
`;

const iconCSS = css`
  color: ${colors.gray300};
  width: 12px;
  height: 12px;
`;

const eraseButtonCSS = css`
  border-radius: 50%;
  min-width: 0;
  height: 18px;
  width: 18px;
  padding: 0;
`;

const titleTextCSS = css`
  color: ${colors.gray900};
  ${typography.mobile.body2}
`;

const contentCSS = css`
  background: ${colors.gray25};
`;
