import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { colors } from "design-system/styles/colors";
import { css, Interpolation, Theme } from "@emotion/react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import PoDone from "design-system/components/atom/IconResource/Icon/PoDone";

export type CheckboxVariantType = "default" | "check";

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    variant?: CheckboxVariantType;
    align?: "top" | "center" | "bottom";
    styles?: Interpolation<Theme>;
  }
>(({ variant = "default", align = "top", styles, ...props }, ref) => (
  <div
    css={[
      checkboxWrapperCSS,
      align === "top" && checkboxAlignTopCSS,
      align === "center" && checkboxAlignCenterCSS,
      align === "bottom" && checkboxAlignBottomCSS,
      styles,
    ]}
  >
    <CheckboxPrimitive.Root
      ref={ref}
      css={checkboxStyles[variant].checkbox}
      {...props}
    >
      <CheckboxPrimitive.Indicator css={checkboxIndicatorCSS} forceMount>
        <PoDone css={checkboxStyles[variant].icon} />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  </div>
));

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };

const checkboxWrapperCSS = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 1px;
`;

const checkboxAlignTopCSS = css`
  //margin-bottom: auto;
`;

const checkboxAlignCenterCSS = css`
  margin-top: auto;
  margin-bottom: auto;
`;

const checkboxAlignBottomCSS = css`
  //margin-top: auto;
`;

const checkboxDefaultCSS = css`
  width: 100%;
  height: 100%;
  padding: 0px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid ${colors.gray200};
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
    border: 1px solid ${colors.gray200};
  }

  &[data-state="checked"] {
    border: 0;
    background-color: black;

    :disabled {
      background-color: ${colors.default.Gray900};
    }
  }
`;

const checkboxCheckCSS = css`
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  color: ${colors.gray200};

  &[data-state="checked"] {
    color: ${colors.gray200};
  }
`;

const checkboxIndicatorCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const iconDefaultCSS = css`
  color: ${colors.default.White};
  width: 18.3px;
  height: 18.3px;
`;

const iconCheckCSS = css`
  width: 24px;
  height: 24px;
`;

export const checkboxStyles: Record<
  CheckboxVariantType,
  Record<"checkbox" | "icon", Interpolation<Theme>>
> = {
  default: {
    checkbox: checkboxDefaultCSS,
    icon: iconDefaultCSS,
  },
  check: {
    checkbox: checkboxCheckCSS,
    icon: iconCheckCSS,
  },
};
