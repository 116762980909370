import Button from "design-system/components/Button/Button";
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { postUsersNewGuest } from "../../../../api/trainer/users/users";
import { queryClient, queryKeys } from "../../../../libs/react-query";
import { PostUsersNewGuestRequest } from "../../../../api/trainer/users/usersTypes";
import { isValidDate } from "../../../../hoock/useDate";
import { css } from "@emotion/react";
import { CrmHeader } from "../../../../common/Header/CrmHeader";
import { TextFieldContent } from "../component/TextFieldContent";
import { TicketDateInput } from "../component/TicketDateInput";
import { DialogPopup } from "design-system/components/DialogPopup/DialogPopup";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { RequireDescription } from "../Page";

export function CreateGuestTicket() {
  const navigate = useNavigate();

  const [isErrorInput, setIsErrorInput] = useState(false);

  const [guestRequest, setGuestRequest] = useState<PostUsersNewGuestRequest>({
    traineeName: "",
    gender: "female",
    phone: "",
    birthdate: undefined,
    ticket: {
      totalCount: 0,
      startAt: "",
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { mutate: addUser } = useMutation({
    mutationFn: postUsersNewGuest,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getUsers());
      navigate("/crm/user-manage");
    },
    onError: () => {},
  });

  const registerTicket = () => {
    if (
      (guestRequest.birthdate == undefined ||
        isValidDate(guestRequest.birthdate?.replaceAll(".", "-"))) &&
      isValidDate(guestRequest.ticket.startAt.replaceAll(".", "-")) &&
      (guestRequest.ticket.endAt == undefined ||
        isValidDate(guestRequest.ticket.endAt.replaceAll(".", "-")))
    ) {
      addUser(guestRequest);
    } else {
      setIsErrorInput(true);
    }
  };

  return (
    <div
      css={css`
        min-height: 100vh;
        height: 100%;
      `}
    >
      <CrmHeader />
      <div css={containerCSS}>
        <div css={contentCSS}>
          <div css={headerContainerCSS}>
            회원 정보
            <RequireDescription />
          </div>
          <TextFieldContent
            isRequired={true}
            title={"이름"}
            placeHolder={"이름을 입력해주세요"}
            setData={(data) => {
              setGuestRequest({
                ...guestRequest,
                ...{ traineeName: data },
              });
            }}
          />
          <div>
            <div css={subtitleTextCSS}>생년월일</div>
            <label css={textareaWrapperCSS}>
              <div css={innerWrapperCSS}>
                <textarea
                  placeholder={"YYYY.MM.DD"}
                  css={[textAreaCSS]}
                  onChange={(event) => {
                    let value = event.target.value.replace(/[^0-9]/g, ""); // 숫자 이외 제거
                    if (value.length > 8) value = value.slice(0, 8); // 최대 8글자 제한

                    // 자동으로 "." 추가
                    if (value.length > 4)
                      value = value.slice(0, 4) + "." + value.slice(4);
                    if (value.length > 7)
                      value = value.slice(0, 7) + "." + value.slice(7);

                    event.target.value = value; // 필드 값 업데이트
                    setGuestRequest({
                      ...guestRequest,
                      birthdate: value.replaceAll(".", "-"),
                    });
                  }}
                />
              </div>
            </label>
          </div>
          <TextFieldContent
            isRequired={true}
            isNumberInput={true}
            title={"휴대전화"}
            placeHolder={"휴대전화 번호를 기입해주세요"}
            description={
              "회원앱을 설치하지 않은 수강생님께 카톡으로 일지를 전송할 수 있어요"
            }
            setData={(data) => {
              setGuestRequest({
                ...guestRequest,
                ...{ phone: data },
              });
            }}
          />
          <div css={textFieldTitleTextCSS}>
            성별
            <div css={genderButtonContainerCSS}>
              <Button
                variant={"tertiary"}
                css={[
                  buttonCSS,
                  guestRequest?.gender == "male" ? buttonSelectedCSS : "",
                ]}
                onClick={() =>
                  setGuestRequest({ ...guestRequest, gender: "male" })
                }
              >
                남성
              </Button>
              <Button
                variant={"tertiary"}
                css={[
                  buttonCSS,
                  guestRequest?.gender == "female" ? buttonSelectedCSS : "",
                ]}
                onClick={() =>
                  setGuestRequest({ ...guestRequest, gender: "female" })
                }
              >
                {" "}
                여성
              </Button>
            </div>
          </div>
        </div>
        <div css={contentCSS}>
          <div css={headerContainerCSS}>
            수강권 정보
            <RequireDescription />
          </div>
          <TextFieldContent
            title={"수강권 횟수"}
            isRequired={true}
            placeHolder={"수강권 횟수를 입력해주세요"}
            description={"이미 진행한 수업일 경우 잔여 회차를 입력해주세요"}
            setData={(value) =>
              setGuestRequest({
                ...guestRequest,
                ticket: { ...guestRequest.ticket, totalCount: Number(value) },
              })
            }
          />
          <TicketDateInput
            setEndDate={(date) =>
              setGuestRequest({
                ...guestRequest,
                ticket: {
                  ...guestRequest.ticket,
                  endAt:
                    date.length == 0 ? undefined : date.replaceAll(".", "-"),
                },
              })
            }
            setStartDate={(date) =>
              setGuestRequest({
                ...guestRequest,
                ticket: {
                  ...guestRequest.ticket,
                  startAt: date.replaceAll(".", "-"),
                },
              })
            }
          />
        </div>
        <div css={contentCSS}>
          <div css={headerContainerCSS}>센터 정보</div>
          <TextFieldContent
            title={"센터 이름"}
            placeHolder={"센터 이름을 입력해주세요"}
            setData={(value) =>
              setGuestRequest({
                ...guestRequest,
                ticket: { ...guestRequest.ticket, centerName: value },
              })
            }
          />
          <TextFieldContent
            title={"센터 전화번호"}
            placeHolder={"센터 전화번호를 입력해주세요"}
            isNumberInput={true}
            setData={(value) =>
              setGuestRequest({
                ...guestRequest,
                ticket: { ...guestRequest.ticket, centerTel: value },
              })
            }
          />

          <TextFieldContent
            title={"결제금액"}
            placeHolder={"결재금액을 입력해주세요"}
            isNumberInput={true}
            setData={(value) =>
              setGuestRequest({
                ...guestRequest,
                ticket: { ...guestRequest.ticket, price: Number(value) },
              })
            }
          />

          <TextFieldContent
            title={"기타내용"}
            description={"회원님께는 노출되지 않는 내용이에요"}
            placeHolder={"메모가 필요한 내용을 적어주세요"}
            setData={(value) =>
              setGuestRequest({
                ...guestRequest,
                ticket: { ...guestRequest.ticket, otherInfo: value },
              })
            }
          />
        </div>
        <div css={finalButtonContainerCSS}>
          <Button
            css={finalButtonCSS}
            variant={"primary"}
            onClick={registerTicket}
            disabled={
              !(
                !!guestRequest.ticket.startAt &&
                !!guestRequest.ticket.totalCount &&
                !!guestRequest.traineeName &&
                !!guestRequest.phone
              )
            }
          >
            수강권 추가하기
          </Button>
        </div>
      </div>
      <DialogPopup
        isOpen={isErrorInput}
        setIsOpen={setIsErrorInput}
        title={"입력 오류입니다"}
        body={"날짜 형식에 맞게 입력해주세요 'YYYY.MM.DD'"}
      />
    </div>
  );
}

const finalButtonContainerCSS = css`
  width: 768px;
  display: flex;
  background: ${colors.lightGray};
`;

const finalButtonCSS = css`
  width: 100%;
  height: 64px;
`;

const buttonSelectedCSS = css`
  width: 100%;
  height: 48px;
  color: ${colors.lime900};
  background: rgba(224, 245, 105, 0.12);
  border: solid 1px ${colors.lime900};
`;
const buttonCSS = css`
  width: 100%;
  height: 48px;
  background: white;
  border: solid 1px ${colors.gray100};
`;

const genderButtonContainerCSS = css`
  display: flex;
  margin-top: 8px;
  gap: 10px;
`;

const textFieldTitleTextCSS = css`
  ${typography.mobile.body1};
  color: ${colors.gray600};
`;

const headerContainerCSS = css`
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 70px */
`;

const descriptionTextCSS = css`
  ${typography.Body4}
`;

const contentCSS = css`
  padding: 60px;
  background: white;
  width: 768px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 8px;
`;

const containerCSS = css`
  background: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  gap: 57px;
  padding: 57px 0;
  align-items: center;
  min-height: 100vh;
  height: 100%;
`;

const textareaWrapperCSS = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11px 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${colors.default.Gray600};
  background-color: white;
`;

const innerWrapperCSS = css`
  display: flex;
  align-items: center;
  width: 100%;
`;

const textAreaCSS = css`
  display: flex;
  width: 100%;
  border: 0;
  padding: 0;
  height: 20px;
  resize: none;
  background-color: transparent;
  color: ${colors.gray800};
  ${typography.mobile.body2};

  ::placeholder {
    color: ${colors.default.Gray300};
  }

  :disabled {
    cursor: not-allowed;
    color: ${colors.default.Gray700};
  }
`;

const subtitleTextCSS = css`
  color: ${colors.gray700};
  ${typography.mobile.body2}
`;
