import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { MainHeader } from "../common/Header/MainHeader";
import { useSearchParams } from "react-router-dom";
import { customFetch } from "../libs/fetch/fetch";
import upperBG from "resource/trainer/homeResource/homepage_upper.png";
import lowerBG from "resource/trainer/homeResource/homepage_lower.png";
import { DescriptionFrame } from "./DescriptionFrame";
import { EventName, ScreenName, useAmplitude } from "../hoock/useAmplitude";
import { Frame4 } from "../trainee/Frame4/Frame4";
import Frame5 from "../trainee/frame5/Fram5";
import Button from "design-system/components/Button/Button";
import appleLogo from "resource/trainer/homeResource/apple_logo.png";
import playStoreLogo from "resource/trainer/homeResource/play_store_logo.png";
import LoginDialogContext from "../common/login-dialog/loginDialogContext";
import { LoginDialog } from "../common/login-dialog/LoginDialog";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { getWidthMediaQuery } from "../styles/mediaQueries";

export function TrainerApp() {
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");
  const hasSentLog = useRef(false);
  const { sendClickEvent, sendScreenViewEvent } = useAmplitude();

  const [isOpen, setIsOpen] = useState(false);
  const appleStore =
    "https://apps.apple.com/us/app/핏츠유-트레이너/id6504490224";
  const playStore =
    "https://play.google.com/store/apps/details?id=kr.co.fitsyou.app.trainer&hl=ko-KR";

  useEffect(() => {
    if (!hasSentLog.current) {
      sendScreenViewEvent({
        eventName: EventName.web_home_main_screen_viewed,
        screenName: ScreenName.web_home_main,
      });
    }
  });

  useEffect(() => {
    if (state == "logout") {
      customFetch(
        {
          app: "user",
          version: "v1",
          path: `/logout`,
        },
        { method: "POST" },
      );
    }
  }, [state]);

  const onsStartWebButtonClick = () => {
    sendClickEvent({
      eventName: EventName.web_start_crm_button_clicked,
      screenName: ScreenName.web_home_main,
    });
    setIsOpen(!isOpen);
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        background: black;
        padding-bottom: 0;
        width: 100%;
        overflow: hidden;
      `}
    >
      <MainHeader />
      <div css={containerCSS}>
        <div css={frame1containerCSS}>
          <div css={emphasisTitleCSS}>
            나에게 딱 맞는 <br />
            PT 일지 관리 핏츠유
          </div>
          <div css={buttonContainerCSS}>
            <Button
              css={buttonCSS}
              onClick={() => window.open(appleStore, "_blank")}
            >
              <img src={appleLogo} css={logoCSS} />
              Apple Store
            </Button>
            <Button
              css={buttonCSS}
              onClick={() => window.open(playStore, "_blank")}
            >
              <img src={playStoreLogo} css={logoCSS} />
              Google Play
            </Button>
          </div>
          <div css={webButtonContainerCSS}>
            <Button css={webButtonCSS} onClick={onsStartWebButtonClick}>
              웹으로 시작하기
            </Button>
          </div>
          <LoginDialogContext.Provider value={{ isOpen, setIsOpen }}>
            <LoginDialog />
          </LoginDialogContext.Provider>
        </div>

        <div css={upperContentCSS}>
          <img src={upperBG} css={upperImageCSS} />
        </div>

        <DescriptionFrame />
        <div css={BottomDecoContainerCSS}>
          <Frame4 />
          <Frame5 />
          <img
            src={lowerBG}
            css={css`
              object-fit: contain;
              bottom: 0;
              position: absolute;
              z-index: -1;
              width: 100%;
              max-height: 1300px;
            `}
          />
        </div>
      </div>
    </div>
  );
}

const webButtonContainerCSS = css`
  width: 100%;
  display: flex;
  justify-content: center;
  border: none;
`;

const webButtonCSS = css`
  border-radius: 2000px;
  padding: 16px 36px;
  background: ${colors.lime600};
  ${typography.mobile.heading2};
  color: black;
`;

const frame1containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  z-index: 2;
`;

const logoCSS = css`
  height: 22px;
  width: 22px;
`;

const buttonContainerCSS = css`
  display: flex;
  width: 100%;
  gap: 17px;
  justify-content: center;
  z-index: 2;
`;

const buttonCSS = css`
  display: flex;
  gap: 14px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  color: white;
  padding: 16px 24px;
  font-size: 12px;

  ${getWidthMediaQuery("pc")} {
    font-size: 16px;
  }
`;

const emphasisTitleCSS = css`
  background: linear-gradient(109deg, #83f9e9 -2.12%, #e0f569 111.08%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 77px;
  font-size: 45px;

  ${getWidthMediaQuery("pc")} {
    font-size: 100px;
    margin-top: 137px;
  }
`;

const BottomDecoContainerCSS = css`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const upperContentCSS = css`
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  height: 600px;
  width: 100%;
  overflow: hidden;

  ${getWidthMediaQuery("pc")} {
    width: 2800px;
    height: 1609px;
  }
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  background-color: black;
`;

const upperImageCSS = css`
  object-fit: cover;
  opacity: 0.3;
  width: 1000px;
  overflow: hidden;

  ${getWidthMediaQuery("pc")} {
    width: 2800px;
    height: 1609px;
  }

  z-index: 1;
`;
