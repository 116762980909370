import Button from "design-system/components/Button/Button";
import { useNavigate } from "react-router";
import { useEffect } from "react";

export function FitsyouAdminPage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/crm/fitsyou-admin/report");
  }, []);

  return (
    <div>
      <Button>핏츠유 어드민</Button>
    </div>
  );
}
