import * as Dialog from "design-system/components/Dialog/Dialog";
import closeIcon from "design-system/components/atom/IconResource/ic_close.svg";
import React from "react";
import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import {
  WorkoutDetailAndDateDTO,
  WorkoutDetailDTO,
} from "../../../../api/trainer/users/usersTypes";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../../libs/react-query";
import { getUsersUserIdWorkoutsWorkoutId } from "../../../../api/trainer/users/users";
import { colors } from "design-system/styles/colors";
import { ImageView } from "design-system/components/ImageView/ImageView";

export interface PrevFeedbackDialogProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  workout: WorkoutDetailDTO;
  userId: number;
  isAdmin?: boolean;
}

export function PrevFeedbackDialog({
  isOpen,
  setIsOpen,
  workout,
  userId,
  isAdmin = false,
}: PrevFeedbackDialogProps) {
  const { data: feedbackData } = useQuery({
    queryFn: () =>
      getUsersUserIdWorkoutsWorkoutId({
        userId,
        workoutId: workout.id ?? 0,
        isCustom: workout.isCustom,
      }),
    queryKey: queryKeys.getUsersUserIdWorkoutsWorkoutId({
      userId,
      isCustom: workout.isCustom,
      workoutId: workout.id ?? 0,
    }),
    enabled: !isAdmin,
  });

  return (
    <Dialog.Root variant={"page"} open={isOpen}>
      <Dialog.Content>
        <div css={dialogHeaderCSS}>
          <div css={dialogHeaderTextCSS}>
            "{workout.workoutName}" 이전 피드백
          </div>
          <img
            src={closeIcon}
            css={closeIconCSS}
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        <div css={feedbackListContainerCSS}>
          {feedbackData?.content.map((item) => {
            return Item(item);
          })}
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function Item(content: WorkoutDetailAndDateDTO) {
  const date = new Date(content.date);
  return (
    <div css={feedbackItemContainerCSS}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 10px;
        `}
      >
        <div
          css={css`
            ${typography.mobile.heading3}
          `}
        >
          {date && <>{date.getFullYear()}년 </>}
          {date && <>{date.getMonth() + 1}월 </>}
          {date && <>{date.getDate()}일</>} 피드백
        </div>
        {content.workoutDetail.feedback &&
          content.workoutDetail.feedback?.length !== 0 && (
            <div
              css={css`
                ${typography.mobile.body2}
              `}
            >
              {content.workoutDetail.feedback}
            </div>
          )}
      </div>
      {content.workoutDetail.images &&
        content.workoutDetail.images.length !== 0 && (
          <div css={imageContainerCSS}>
            <div>사진</div>
            <div css={imageListContainerCSS}>
              {content.workoutDetail.images.map((image) => {
                return (
                  <a href={image.url} target="_blank">
                    <ImageView
                      src={image.url}
                      styles={imageCSS}
                      isClickable={false}
                    />
                  </a>
                );
              })}
            </div>
          </div>
        )}
      {content.workoutDetail.videos &&
        content.workoutDetail.videos.length !== 0 && (
          <div css={imageContainerCSS}>
            <div>동영상</div>
            <div css={imageListContainerCSS}>
              {content.workoutDetail.videos.map((video) => {
                // TODO: 동영상 대응 @sello
                return ImageView({
                  type: "video",
                  videoSrc: video.url,
                  src: video.thumbnail,
                  styles: imageCSS,
                });
              })}
            </div>
          </div>
        )}
    </div>
  );
}

const imageCSS = css`
  width: 100px;
  height: 100px;
`;

const imageContainerCSS = css`
  ${typography.mobile.body2}
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const imageListContainerCSS = css`
  display: flex;
  gap: 10px;
  overflow: auto;
`;

const feedbackListContainerCSS = css`
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  gap: 20px;
  max-height: 650px;
  overflow-y: scroll !important;
`;

const feedbackItemContainerCSS = css`
  background: ${colors.lightGray};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
`;

const closeIconCSS = css`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const dialogHeaderCSS = css`
  display: flex;
  padding: 14px;
`;

const dialogHeaderTextCSS = css`
  width: 100%;
  text-align: center;
  ${typography.mobile.heading3}
`;
