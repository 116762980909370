import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import * as RadioGroup from "design-system/components/RadioGroup/RadioGroup";
import { ReportType } from "../../../../../api/common/commonEnumType";
import { useContext } from "react";
import CreateContext from "../../createContext";

type ReportTypeCheckProps = {
  reportType: ReportType;
};

export function ReportTypeCheck({ reportType }: ReportTypeCheckProps) {
  const { data, setData } = useContext(CreateContext);
  // setData();
  return (
    <div css={containerCSS}>
      <span css={testCSS}>일지 유형 선택</span>
      <div css={typeListCSS}>
        <RadioGroup.Root
          defaultValue={data?.lessonResultType}
          onValueChange={(reportType: ReportType) => {
            setData((prevState) => ({
              ...prevState,
              lessonResultType: reportType,
            }));
          }}
          styles={labelComponentListContainerCSS}
        >
          <RadioGroup.ItemWithLabel
            value="feedback_only"
            styles={labelComponent2CSS}
          >
            <Item title={"간단일지"} subtitle={"종합피드백"} />
          </RadioGroup.ItemWithLabel>

          <RadioGroup.ItemWithLabel
            value="feedback_workout"
            styles={labelComponent2CSS}
          >
            <Item title={"기본일지"} subtitle={"간단일지 + 운동별 피드백"} />
          </RadioGroup.ItemWithLabel>

          <RadioGroup.ItemWithLabel
            value="feedback_workout_set_weight"
            styles={labelComponent2CSS}
          >
            <Item
              title={"상세일지"}
              subtitle={"기본일지 + 세트당 무게 및 횟수"}
            />
          </RadioGroup.ItemWithLabel>
        </RadioGroup.Root>
      </div>
    </div>
  );
}

const badgeCSS = css`
  display: flex;
  background: ${colors.gray700};
  color: white;
  justify-content: center;
  align-content: center;
  height: 25px;
  align-items: center;
  width: 65px;
  border-radius: 4px;
  ${typography.mobile.body4}
`;

type ItemProps = {
  title: string;
  subtitle: string;
};

function Item({ title, subtitle }: ItemProps) {
  return (
    <div css={typeItemCSS}>
      <div css={itemTitleTextCSS}>{title}</div>
      <div css={itemSubTitleTextCSS}>{subtitle}</div>
    </div>
  );
}

const labelComponentListContainerCSS = css`
  //width: 100%;
  gap: 30px;
  justify-content: space-between;
`;

const labelComponentCSS = css`
  display: flex;
  gap: 16px;
`;

const labelComponent2CSS = css`
  display: flex;
`;
const itemSubTitleTextCSS = css`
  ${typography.mobile.body2}
`;

const itemTitleTextCSS = css`
  ${typography.mobile.heading4}
`;

const typeItemCSS = css`
  display: flex;
  flex-direction: column;
`;

const testCSS = css`
  ${typography.mobile.heading1};
  color: ${colors.default.Gray};
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-width: 600px;
`;

const typeListCSS = css`
  display: flex;
  flex-direction: row;
  padding: 40px;
  border: 1px solid ${colors.gray100};
  border-radius: 8px;
`;
