import { css } from "@emotion/react";
import { Textarea } from "design-system/components/Textarea/Textarea";
import { typography } from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";

export interface TextFieldContentProps {
  isRequired?: boolean;
  title: string;
  description?: string;
  defaultData?: string;
  placeHolder: string;
  setData: (content: string) => void;
  isEditable?: boolean;
  isNumberInput?: boolean;
}

export const TextFieldContent = ({
  isRequired = false,
  title,
  description,
  setData,
  placeHolder,
  defaultData,
  isEditable = true,
  isNumberInput = false,
}: TextFieldContentProps) => {
  return (
    <div css={textFieldContainerCSS}>
      <div css={textFieldTitleTextCSS}>
        {title}
        {isRequired && (
          <span
            css={css`
              color: red;
            `}
          >
            *
          </span>
        )}
        {description && (
          <div css={textFieldDescriptionTextCSS}>{description}</div>
        )}
      </div>
      <Textarea
        isNumberInput={isNumberInput}
        disabled={!isEditable}
        placeholder={placeHolder}
        defaultValue={defaultData}
        onChange={(event) => {
          setData(event.target.value);
        }}
      />
    </div>
  );
};

const textFieldContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const textFieldTitleTextCSS = css`
  ${typography.mobile.body1};
  color: ${colors.gray600};
`;
const textFieldDescriptionTextCSS = css`
  ${typography.mobile.body2}
`;
